const get = async (path, options) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/api${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...(options?.headers || {}),
    },
    ...(options | {})
  });
  const data = await result.json();
  if (result.status >= 400) {
    throw new Error(data.message);
  } else {
    return data;
  }
};

const post = async (path, body, options) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/api${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...(options?.headers || {}),
    },
    body: JSON.stringify(body),
    ...(options | {})
  });
  const data = await result.json();
  if (result.status >= 400) {
    throw new Error(data.message);
  } else {
    return data;
  }
};

const login = async (email, password) => {
  return post('/auth/login', { email, password });
};

const register = async ({ firstname, lastname, email, password }) => {
  return post('/auth/register', { firstname, lastname, email, password });
};

const getProfile = async (jwt) => {
  return get('/profile', {
    headers: {
      'Authorization': jwt,
    },
  });
};

const createMeeting = async (jwt) => {
  return post('/meetings', {}, {
    headers: {
      'Authorization': jwt,
    }
  })
};

export {
  login,
  register,
  getProfile,
  createMeeting,
}
