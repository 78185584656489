import authForm from '../auth-form.module.scss';
import { login } from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const navigate = useNavigate();
  const { setJwt } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { email, password } = e.target;
    try {
      const data = await login(email.value, password.value);
      setJwt(data.token);
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Error login', err);
      alert(err.message);
    }
  }

  return (
    <div className={authForm.container}>
      <h1>Connexion</h1>
      <form onSubmit={handleSubmit} className={authForm.form}>
        <input type={'text'} name={'email'} placeholder={'Email'}/>
        <input type={'password'} name={'password'} placeholder={'Mot de passe'}/>
        <input type={'submit'} name={'Se connecter'}/>
      </form>
    </div>
  )
}
