import authForm from '../auth-form.module.scss';
import { register } from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const { setJwt } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { firstname, lastname, email, password } = e.target;
    try {
      const data = await register({
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        password: password.value,
      });
      setJwt(data.token);
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Error login', err);
      alert(err.message);
    }
  }

  return (
    <div className={authForm.container}>
      <h1>Inscription</h1>
      <form onSubmit={handleSubmit}>
        <input type={'text'} name={'firstname'} placeholder={'Prénom'} />
        <input type={'text'} name={'lastname'} placeholder={'Nom'} />
        <input type={'text'} name={'email'} placeholder={'Email'} />
        <input type={'text'} name={'confirmEmail'} placeholder={'Confirmation d\'email'} />
        <input type={'password'} name={'password'} placeholder={'Mot de passe'} />
        <input type={'password'} name={'confirmPassword'} placeholder={'Confirmation de mot de passe'} />
        <input type={'submit'} value={'Créer mon compte'} />
      </form>
    </div>
  )
}
