import styles from './style.module.scss'
import { useNavigate } from 'react-router-dom';
import apiSocket from '../../services/socket';

export default function Home() {
  const navigate = useNavigate();

  const joinMeeting = (code) => {
    navigate(`/meetings?code=${code}`);
  }

  const handleJoinMeeting = async (e) => {
    e.preventDefault();
    if (!e.target.code.value) return;
    joinMeeting(e.target.code.value);
  };

  const handleCreateMeeting = async (e) => {
    e.preventDefault();
    apiSocket.emit('sessions:create');
    apiSocket.on('sessions:create', ({ id, code }) => {
      joinMeeting(code);
    });

  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleJoinMeeting}>
        <input type={'text'} placeholder={'Code meeting'} name={'code'} />
        <input type={'submit'} value={'Rejoindre'} />
      </form>
      <div className={styles.separator}/>
      <form onSubmit={handleCreateMeeting}>
        <input type={'submit'} value={'Créer un nouveau meeting'} />
      </form>
    </div>
  )
}
