import OT from '@opentok/client';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import styles from './style.module.scss';

import InputSelect from '../../components/inputSelect/InputSelect';
import apiSocket from '../../services/socket';
import Subtitles from '../../components/subtitles/Subtitles';

const vonageApiKey = '47751221';

function Meeting() {
  const [ searchParams ]  = useSearchParams();
  const [ sessionId, setSessionId ] = useState(null);
  const [ token, setToken ] = useState(null);
  const [ connected, setConnected ] = useState(false);

  const [ device, setDevice ] = useState(null);
  const [ language, setLanguage ] = useState(null);


  const code = useMemo(() => {
    return searchParams.get('code');
  }, [ searchParams ]);

  const handleVonageError = (error) => {
    if (error) {
      alert(error.message);
    }
  };

  const initializeVonageSession = () => {
    if (!token || !sessionId) return;
    const session = OT.initSession(vonageApiKey, sessionId);

    // Subscribe to a newly created stream
    session.on('streamCreated', function(event) {
      session.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        audioVolume: 0,
      }, handleVonageError);
    });

    // Create a publisher
    var publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      videoSource: null,
      publishVideo: false,
      publishAudio: false,
    }, handleVonageError);

    // Connect to the session
    session.connect(token, function(error) {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        handleVonageError(error);
      } else {
        session.publish(publisher, handleVonageError);
      }
    });
    apiSocket.emit('sessions:joined', { language, sessionId });
  };
  useEffect(() => {
    initializeVonageSession();
  }, [ token, sessionId ]);

  const registerSocketEvents = () => {
    apiSocket.on('sessions:token', (data) => {
      setConnected(true);
      setToken(data.token);
      setSessionId(data.sessionId);
    });

    apiSocket.on('disconnect', (data) => {
      console.log('disconnected', data);
      setConnected(false);
    });

    apiSocket.on('connect', () => {
      console.log('apiSocket connected');
      setConnected(true);
    })

    return () => {
      apiSocket.removeAllListeners();
    };
  };

  useEffect(registerSocketEvents, []);

  useEffect(() => {
    if (connected) {
      setTimeout(() => {
        console.log('language', language, 'sessionId', sessionId);
        apiSocket.emit('sessions:joined', { language, sessionId });
      }, 1000);
    } else {
      console.log('trying to reconnect');
      apiSocket.connect();
    }
  }, [ connected ]);

  const joinRoom = async () => {
    apiSocket.emit('sessions:token', { code });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>iBridgePeople V4</h1>
        <div className={styles.settings}>
          <InputSelect
            title={'Settings'}
            onLanguageSelected={setLanguage}
            onDeviceSelected={setDevice}
          />
          <div className={styles.button} onClick={() => joinRoom(true)}>
            <p>{'Start'}</p>
          </div>
        </div>
        <span>v4.5.5</span>
      </div>
      <div className={styles.body}>
        {/*{!connected && <div className={styles.notConnected}>*/}
        {/*  <p className={styles.notConnectedText}>Not connected</p>*/}
        {/*</div>}*/}
        <Subtitles device={device} language={language} />
        {/*<Audio />*/}
        <div id="videos" className={styles.videos}>
          <div id="subscriber" className={styles.subscriber}></div>
          <div id="publisher" className={styles.publisher}></div>
        </div>
      </div>
    </div>
  );
}

export default Meeting;
